<template>
  <div class="pie" style="--p:40">20%</div>
  <div class="pie" style="--p:40;--c:darkblue;--b:10px">40%</div>
  <div class="pie no-round" style="--p:60;--c:purple;--b:15px">60%</div>
  <div class="pie animate no-round" style="--p:80;--c:orange">80%</div>
  <div class="pie animate" style="--p:90;--c:lightgreen">90%</div>
</template>
<script>
export default {
    
}
</script>
<style src="../assets/css/pie.css" scoped>
    
</style>